
import { Options, Vue, Watch } from "vue-property-decorator";
import * as api from "@/api/anquanxunjian";
import clapApi from "@/api/clap";
import File from "@/api/file/index.ts";
import { parseTime } from "@/utils/index";
// import PersonliableDialog from "./personliableDialog.vue";
@Options({
  name: "Clapadd",
  components: {
    // PersonliableDialog,
  },
})
export default class extends Vue {
  bAllow = true;
  submitform = {
    title: "",
    areaId: "",
    remark: "",
    fileIds: "",
    deptName: "",
    limitDate: "",
  };
  showAreaPicker = false;
  showDatePopup = false;
  uploadFiles: any = [];
  private areaColumns: any[] = [];
  areaName = "";
  selectDate = new Date();
  limitDate = "";
  onAreaConfirm(val: any) {
    if (val.name === "新增") {
      this.$router.push("/app/xunjianareaadd");
    } else {
      this.areaName = val.name;
      this.submitform.areaId = val.id;
    }
    this.showAreaPicker = false;
  }
  fOpenAreaPicker() {
    api
      .projectArealist({
        projectId: this.$store.state.project.projectinfo.projectId,
      })
      .then((res) => {
        this.areaColumns = res.data;
        this.areaColumns.unshift({
          name: "新增",
          id: 0,
        });
        this.showAreaPicker = true;
      });
  }

  afterRead(files: any) {
    let filearr: any = [];
    if (!Array.isArray(files)) {
      filearr = [files];
    } else {
      filearr = [...files];
    }
    filearr.map((file: any) => {
      file.status = "uploading";
      file.message = "上传中...";
      File.uploadFile(
        "inspectionmobile/",
        file.file.name,
        file.file,
        true
      ).then((res: any) => {
        file.status = "done";
        file.message = "上传完毕";
        file.id = res.data.id;
      });
    });
  }
  fSumbitForm() {
    (this as any).$refs.form.submit();
  }
  @Watch("uploadFiles", { deep: true })
  watchFile(files: any) {
    let obj = files.find((file: any) => {
      return file.status === "uploading";
    });
    if (!obj) {
      this.bAllow = true;
    } else {
      this.bAllow = false;
    }
  }
  async onSubmit() {
    let arr = "";
    this.uploadFiles.map((item: any) => {
      arr += "," + item.id;
    });
    this.submitform.fileIds = arr.substr(1, arr.length - 1);
    let result: any = await clapApi.mobileanyPhotosave({
      projectId: this.$store.state.project.projectinfo.projectId,
      ...this.submitform,
    });
    if (result.code !== 0) {
      return this.$toast.fail(result.msg);
    }
    this.$toast.success({
      message: result.msg,
      forbidClick: true,
      onClose: () => {
        this.submitform = {
          title: "",
          areaId: "",
          remark: "",
          fileIds: "",
          deptName: "",
          limitDate: "",
        };
        this.areaName = "";
        this.limitDate = "";
        this.uploadFiles = [];
        (this as any).$mitter.emit("clapRefresh");
        this.$router.go(-1);
      },
    });
  }
  fConfirmDate(val: Date) {
    this.selectDate = val;
    this.submitform.limitDate = parseTime(
      this.selectDate,
      "{y}-{m}-{d} 00:00:00"
    ) as string;
    this.limitDate = parseTime(this.selectDate, "{y}-{m}-{d}") as string;
    this.showDatePopup = false;
  }
  fGoBack() {
    (this as any).$refs.form.resetValidation();
    this.submitform = {
      title: "",
      areaId: "",
      remark: "",
      fileIds: "",
      deptName: "",
      limitDate: "",
    };
    this.areaName = "";
    this.limitDate = "";
    this.uploadFiles = [];
    this.$router.go(-1);
  }
}
